import * as React from 'react';
import * as ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './config/theme';
import './App.css';

const rootElement = document.getElementById('root');

ReactDOM.render(
  <React.StrictMode >
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <App className="App" />
    </ThemeProvider>
  </React.StrictMode>,
  rootElement
);
