import { useState, MouseEvent, Fragment, useEffect } from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import { User, getAuth } from 'firebase/auth';
import logo from "../resources/logo.jpeg";
import { Link } from 'react-router-dom';

export default function AccountMenu() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [currentUser, setCurrentUser] = useState<User | null>(getAuth().currentUser);
    const auth = getAuth();
    const open = Boolean(anchorEl);
    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = () => {
        getAuth().signOut();
        document.location.reload();
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(
            (user) => {
                if (user) {
                    setCurrentUser(user);
                }
            }
        );
  
        return () => {
            unsubscribe(); // Unsubscribe from onAuthStateChanged when component is unmounted
        }
      }, [auth]);


    return (
        <Fragment>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'center',
                    justifyContent: 'flex-end'
                }}
                paddingTop={'10px'}
                paddingX='20px'
            >
                <Box sx={{ marginRight: 'auto' }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: 'auto' }}>
                    <Link to="/">
                        <img
                            style={{
                                height: '35px',
                                width: '35px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            src={logo}
                            alt="logo"
                        />
                    </Link>
                    <Typography variant="body1" sx={{ marginLeft: '10px' }}>
                    <Link to="/" style={{ textDecoration: 'none', color: 'inherit', marginLeft:'10px' }}>
                        Home
                    </Link>
                </Typography>
                </div>
                </Box>
                <Typography sx={{ minWidth: 100 }}>
                    <Link to="/services" style={{ textDecoration: 'none', color: 'inherit' }}>
                        Services
                    </Link>
                </Typography>
                
                { currentUser
                    ?
                        <Tooltip title="Account settings">
                            <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                {
                                    currentUser?.photoURL
                                    ?
                                        <Avatar alt="profile picture" src={currentUser?.photoURL!} />
                                    :
                                        <Avatar sx={{ width: 32, height: 32 }}>
                                            {currentUser?.email?.toUpperCase().charAt(0)}
                                        </Avatar>
                                }
                            </IconButton>
                        </Tooltip>
                    : 
                        <Typography>
                            <Link to="/login?redirectOnLogin=true" style={{ textDecoration: 'none', color: 'inherit' }}>
                                Log In
                            </Link>
                        </Typography>
                }
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </Fragment>
    );
}