import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Paper, Grid, Typography, Link, Button, IconButton, Snackbar } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PictureCarousel from "./PictureCarousel";
import theme from "../config/theme";

const FrontPage: React.FC = () => {
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const handleButtonClick = () => {
    const email = 'daisyhodgepilates@gmail.com';
    const subject = encodeURIComponent('Pilates Inquiry');
    window.open(`mailto:${email}?subject=${subject}`);
  };

  const copyEmailToClipboard = () => {
    const email = 'daisyhodgepilates@gmail.com';
    const textArea = document.createElement('textarea');
    textArea.value = email;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
      <Grid
        container
        justifyContent={"center"}
        spacing={2}
        rowSpacing={4}
        paddingX="20px"
        paddingTop="40px"
      >
        <Grid item xs={12}>
          <Typography
            component="h3"
            variant="h3"
            align="center"
            justifyContent={'center'}
            color="text.primary"
            style={{
              fontWeight: 'bold', 
              textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
            }}
          >
            DAISY HODGE PILATES
          </Typography>
          <Typography
            component="h5"
            variant="h5"
            align="center"
            color="text.primary"
            marginBottom={'20px'}
          >
            at
          </Typography>
          <Typography
            component="h5"
            variant="h5"
            align="center"
            color="text.primary"
            gutterBottom
          >
            <Link 
              component={RouterLink} 
              to='https://www.themovementstudiodurham.com/' 
              style={{ 
                textDecoration: 'none', 
                color: 'inherit', 
                fontWeight: 'bold',
                padding: '10px 15px 10px 15px',
                backgroundColor: theme.palette.secondary.dark,
              }}>
            THE MOVEMENT STUDIO
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
          <PictureCarousel />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Paper elevation={3}>
            <Typography
              component="body"
              variant="body2"
              align="center"
              color="text.primary"
              marginBottom="20px"
              sx={{ width: '100%', height: '100%', padding: '20px'}}
            >
              Daisy (she/her) is a dance teacher, choreographer, and Pilates instructor in the Durham area.
              She discovered her love of Pilates at Appalachian State University, where she studied Dance.
              Somatic practices played a vital role in Daisy’s education, which emphasized keeping bodies
              safe, healthy, and energized. Since her first introduction to the practice five years ago,
              she has completed a Pilates program at Drexel University, taught in various Philadelphia studios,
              and has now returned to her home state of NC. In addition to teaching mat and equipment Pilates
              at The Movement Studio, Daisy also instructs dance at American Dance Festival’s Scripps Studios
              year-round, working with dancers of all ages and skill levels. She is passionate about these
              movement modalities as healing modalities, and firmly believes that anyone can experience joy
              and freedom to move in their bodies. Daisy will empower you to explore new and familiar ways
              to be in a space with attention to breath, strength, flexibility, and awareness. Come join her
              in mat classes or book a private/duet if you want a more customized experience on the equipment!
              </Typography>
          </Paper>
        </Grid>
        <Grid xs={12} sm={8} style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
          <Button variant="contained" onClick={handleButtonClick}>
            {'Contact Daisy'}
          </Button>
          <IconButton onClick={copyEmailToClipboard} style={{ marginLeft: '10px' }}>
            <ContentCopyIcon />
          </IconButton>
        </Grid>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message="Email copied to clipboard!"
        />
    </Grid>
  );
};

export default FrontPage;
