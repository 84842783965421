import { Box } from "@mui/material";
import AccountMenu from "./AccountMenu";
import StickyFooter from "./StickyFooter";

export default function PageContainer({ children }: any) {
    return (
        <Box
          sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100vh',
              height: '100%',
          }}
        >
            <Box
              component="main"
              sx={{
                  flexGrow: 1,
              }}
            >
                <AccountMenu />
                {children}
            </Box>
        <StickyFooter />
        </Box>
    );
}