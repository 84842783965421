import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import "../App.css"

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#C4E1F2',
    },
    secondary: {
      main: '#F2F0CE',
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    fontFamily: [
      'Open Sans',
    ].join(','),
  }
});

export default theme;
