import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import { Grid, Typography, Dialog, DialogContent, Paper, Button } from "@mui/material";
import { OpenInBrowser } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useParams } from "react-router-dom";
import { addDoc, collection, doc, getDoc, getDocFromServer, getFirestore } from "firebase/firestore";
import { prices } from "../util";

export default function SessionPackPurchase() {
    const { service, units } = useParams();
    const serviceName = service === 'private-session' ? 'Private Session' : 'Duet Session';
    const auth = getAuth();
    const [currentUser, setCurrentUser] = useState<string | undefined>(getAuth().currentUser?.uid);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [credits, setCredits] = useState<number>(0);
    const [paymentLinkLoading, setPaymentLinkLoading] = useState<boolean>(false);

    useEffect(() => {
        if (units !== "5" && units !== "10") {
            window.location.href = `/`;
        }
    }, [units]);

    useEffect(() => {
      const getUserCredits = async (userId: string) => {
        // Query customers collection for user, if user exists, get credits
        const db = getFirestore();
        const customerRef = doc(db, 'customers', userId);
        const customerDoc = await getDoc(customerRef);
        if (customerDoc.exists()) {
          const customerData = customerDoc.data();
          if (customerData) {
            const credits = 
              serviceName === 'Private Session' 
              ? customerData.sessionCredits 
              : customerData.duetCredits;
            setCredits(credits ?? 0);
          }
        }
      }

      const unsubscribe = auth.onAuthStateChanged(
          (user) => {
              if (user) {
                  setCurrentUser(user.uid);
                  getUserCredits(user.uid);
                  setDialogOpen(false);
              } else {
                  setDialogOpen(true);
              }
          }
      );

      return () => {
          unsubscribe(); // Unsubscribe from onAuthStateChanged when component is unmounted
      }
    }, [auth, serviceName]);

      

      const handlePurchase = async () => {
        setPaymentLinkLoading(true);
        // Create pack purchase document for tracking
        const db = getFirestore();
        const packCheckoutRef = collection(db, 'customers', currentUser!, 'pack_purchases');
        const newPackCheckoutRef = await addDoc(packCheckoutRef, {
          service: serviceName,
          units: +units!,
          credited: false
        });
        // Get price ID from units
        const priceId = prices.find(price => price.units === +units! && price.name === serviceName)?.priceId;
        // Create a new checkout session to get a payment URL
        
        const checkoutSessionRef = collection(db, 'customers', currentUser!, 'checkout_sessions');
        const newCheckoutRef = await addDoc(checkoutSessionRef, {
            mode: "payment",
            price: priceId,
            success_url: `${window.location.origin}/confirmation/packs/${newPackCheckoutRef.id}/${currentUser}`,
            cancel_url: `${window.location.origin}/packs/${units}`,
            metadata: {
                packPurchaseId: newPackCheckoutRef.id
            }
        });
        // Wait for a second to allow the cloud function to run, then poll the server for the payment URL
        await new Promise(r => setTimeout(r, 1000));
        let paymentUrl;
        let polls = 0;
        do {
          // Increase the pollTime by 500ms for each poll after the first 3
          const pollTime = 500 + (polls > 3 ? (polls - 3) * 500 : 0);
          await new Promise(r => setTimeout(r, pollTime));
          paymentUrl = (await getDocFromServer(newCheckoutRef)).data()?.url;
          if (paymentUrl) {
            break;
          }
        } while (!paymentUrl && polls++ < 10);
        if (polls === 10) {
          console.error("Failed to get payment URL from server.");
          return;
        }
        // Redirect to the payment URL
        window.location.href = paymentUrl;
      }

      let price = 0;
      if (serviceName === 'Private Session') {
        price = +units! === 10 ? 850 : 435
      } else {
        price = +units! === 10 ? 500 : 260
      }

      return (
        <>
            <Grid
                container
                justifyContent={"center"}
                rowSpacing={4}
                paddingX="20px"
                paddingTop="40px"
            >
                {currentUser &&
                <>
                    <Grid item xs={12}>
                        <Typography
                            component="h3"
                            variant="h3"
                            align="center"
                            color="text.primary"
                            gutterBottom
                            style={{ fontWeight: 'bold', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)' }}
                        >
                            {`${serviceName}: ${units} Pack`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Paper elevation={3} style={{ padding: '20px', display: 'flex', flexDirection: 'column' }}>
                            <Typography
                                component="body"
                                variant="body1"
                                align="center"
                                color="text.primary"
                                width={'100%'}
                                height={'100%'}
                                marginBottom="20px"
                            >
                                User currently has <b>{credits ?? 'N/A'}</b> {serviceName} credits.
                                <br />
                                <br />
                                {` Click button below to purchase ${units} credits for $${price}.`}
                            </Typography>
                            <LoadingButton
                                loading={paymentLinkLoading}
                                loadingPosition="end"
                                variant="contained"
                                onClick={handlePurchase}
                                endIcon={<OpenInBrowser />}
                            >
                                {!paymentLinkLoading ? `Purchase ${units} Pack` : 'Redirecting for Payment'}
                            </LoadingButton>
                        </Paper>
                    </Grid>
                </>
                }
            </Grid>
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent style={{ paddingTop: '10px', textAlign: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography
                            component="body"
                            variant="body1"
                            align="center"
                            color="text.primary"
                            height={'100%'}
                            width={'100%'}
                            marginBottom="20px"
                        >
                            Click below to sign in for Pack Purchase
                        </Typography>
                        <Button
                            style={{ flexGrow: 1, minWidth: '110px' }}
                            variant="contained"
                            onClick={async () => window.open('/login')}
                        >
                            Sign In
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
      );

}
