import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { Grid, Typography } from '@mui/material';
import TimeBlockPicker from './TimeBlockPicker';
import { Timestamp, collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import theme from '../config/theme';
import { getSchedulerSubtext, isUserAdmin } from '../util';
import { getAuth } from 'firebase/auth';

export default function Scheduler({ serviceName }: { serviceName: string }) {
  const [isLoading, setIsLoading] = useState(true);
  const [unavailableDays, setUnavailableDays] = useState<Set<number>>(new Set<number>());
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [selectedMonth, setSelectedMonth] = useState<Dayjs>(dayjs());
  const [slots, setSlots] = useState<any[]>([]);

  const findSlots = async (date: Dayjs) => {
    const db = getFirestore();
    const slotsRef = collection(db, 'slots');
    // Get all bookings in the same month as selectedMonth
    const slotsQuery = query(slotsRef, 
      where('startTime', '>=', date.startOf('month').toDate()), 
      where('startTime', '<=', date.endOf('month').toDate()),
      where('service', '==', serviceName)
    );
    const slotDocsRef = await getDocs(slotsQuery);
    let slotDocs = slotDocsRef.docs;
    if (!isUserAdmin(getAuth().currentUser)) {
      slotDocs = slotDocs.filter(doc => doc.data().active && doc.data().bookingIds.length < doc.data().maxBookings);
    }
    const slotDates: Dayjs[] = slotDocs.map(doc => dayjs((doc.data().startTime as Timestamp).toMillis()));
    const unavailableDates: Set<number> = new Set<number>(
      Array.from({length: date.daysInMonth()}, (_, i) => i + 1)
    );
    setUnavailableDays(unavailableDates);
    slotDates.forEach(date => {
      const day = date.date();
      // If the slot is in the past or on the next day, don't allow booking
      if (serviceName !== 'Drop-In Class' && date.isBefore(dayjs().add(24, 'hours'), 'days')) return;
      if (serviceName === 'Drop-In Class' && date.isBefore(dayjs().subtract(1, 'hours'))) return;
      unavailableDates.delete(day);
    });
    setSlots(slotDocs);
    setUnavailableDays(unavailableDates);
    setIsLoading(false);
  };

  useEffect(() => {
    findSlots(selectedMonth);
  }, [selectedMonth]);

  const handleMonthChange = (date: Dayjs) => {
    setSelectedMonth(date);
    setSelectedDate(null);
    setIsLoading(true);
    setUnavailableDays(new Set<number>());
    findSlots(date);
  };

  return (
    <>
      <Typography
        component="h4"
        variant="h4"
        align="center"
        color="text.primary"
        gutterBottom
        paddingTop='40px'
        style={{ fontWeight: 'bold', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)' }}
      >
        {serviceName}
      </Typography>
      <Grid container justifyContent="center" alignItems="center" padding='25px'>
        <Grid item sm={12} md={5}>
          <DateCalendar
            value={selectedDate}
            onChange={(date) => { if (!date?.isSame(selectedDate)) setSelectedDate(date) }}
            onViewChange={() => { setSelectedDate(null) }}
            loading={isLoading}
            disableHighlightToday={true}
            onMonthChange={handleMonthChange}
            renderLoading={() => <DayCalendarSkeleton />}
            shouldDisableDate={(day) => unavailableDays.has(day.date())}
            shouldDisableMonth={(day) => day.isBefore(dayjs().startOf('month'))}
          />
        </Grid>
        {selectedDate && (
          <Grid item sm={8} md={6}>
            <TimeBlockPicker 
              selectedDate={selectedDate.toDate()} 
              serviceName={serviceName} 
              slots={slots
                .map(slot => ({ 
                  id: slot.id, 
                  ...slot.data(), 
                  startTime: dayjs((slot.data().startTime as Timestamp)?.toMillis())
                    .set('second', 0)
                    .set('millisecond', 0),
                }))
                .filter(slot => slot.startTime.date() === selectedDate.date())
                .sort((a, b) => a.startTime.diff(b.startTime))
              }
            />
          </Grid>
        )}
          <Grid item xs={12}>
            <Typography
              component="body"
              variant="body2"
              align="center"
              color="text.primary"
              sx={{backgroundColor: theme.palette.secondary.light}}
              fontSize={'12px'}
              width={'100%'}
              height={'100%'}
              gutterBottom
              paddingTop='40px'
            >
              {getSchedulerSubtext(serviceName)}
            </Typography>
          </Grid>
      </Grid>
    </>
  );
}