import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import theme from '../config/theme';

function Copyright() {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Typography 
        variant="body2" 
        color="text.secondary" 
        sx={{ fontSize: { xs: '10px', sm: theme.typography.body2.fontSize } }}>
        {`Copyright © Josh Taekman ${new Date().getFullYear()}.`}
      </Typography>
      {
        process.env.REACT_APP_ENV === 'production' 
        ? <></>
        :
        <Typography 
          variant="body2" 
          color="red" 
          sx={{ fontSize: { xs: '10px', sm: theme.typography.body2.fontSize } }}>
          {`(Test Mode)`}
        </Typography>
      }
      <Typography 
        variant="body2" 
        color="text.secondary" 
        sx={{ 
          fontSize: { xs: '10px', sm: theme.typography.body2.fontSize },
        }}>
        {`Logo © Laura Williams ${new Date().getFullYear()}.`}
      </Typography>
  </div>
  );
}

export default function StickyFooter() {
  return (
    <Box
        component="footer"
        sx={{
            px: { xs: 2, sm: 4 },
            py: { xs: 1, sm: 2 },
            mt: 'auto',
            backgroundColor: '#dbd9b2',
        }}
    >
        <Copyright />
    </Box>
  );
}