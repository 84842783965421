import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { Grid, Paper, Typography } from '@mui/material';
import { getAuth } from 'firebase/auth';
import logo from "../resources/logo.jpeg";
import { uiConfig } from '../config/firebase';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

function SignInScreen() {
    const [searchParams] = useSearchParams();
    let redirectOnLoginRaw = searchParams.get("redirectOnLogin");
    console.log(redirectOnLoginRaw);
    const redirectOnLogin = redirectOnLoginRaw === "true" ? true : false;

    useEffect(() => {
        getAuth().onAuthStateChanged(
          (user) => { 
            if (!!user) {
                if (redirectOnLogin) window.location.href = "/";
                else window.close();
            }
        }
        );
      }, [redirectOnLogin]);

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            paddingX={"16px"}
            paddingY={"50px"}
        >
            <Grid item>
                <Typography
                    component="h3"
                    variant="h3"
                    align="center"
                    color="text.primary"
                    gutterBottom
                    style={{ fontWeight: 'bold', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)' }}
                >
                    Daisy Hodge Pilates
                </Typography>
          </Grid>
            <Grid item>
                <img
                    style={{ height: "300px", width: "300px" }}
                    src={logo}
                    alt="logo"
                />
            </Grid>
            <Grid item>
                <Paper>
                    <h4 style={{ padding: "20px", textAlign: "center" }}>
                        Please sign-in below to continue.
                    </h4>
                </Paper>
            </Grid>
            <Grid item>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={getAuth()} />
            </Grid>
        </Grid>

    );
}

export default SignInScreen;